<template>
    <div>
        <!-- card admin -->
        <el-dialog v-if="$store.state.userInfo" :modal-append-to-body="false" :visible.sync="dialogVisibleCard"
            custom-class="custom-dialog card-dialog">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ $t('卡片管理') }}</h1>
                </div>

            </div>
            <div class="total__inner site-card-tab">
                <div class="header-right">
                    <el-button type="primary" class="button_jade" size="mini" @click="showAddAccount" v-if="(activeTab == $t('银行卡') && myBank.length > 0) ||
                        (activeTab == $t('USDT') && myUsdt.length > 0) ||
                        (activeTab == $t('电子钱包') && myWallet.length > 0) ||
                        (activeTab == $t('PIX') && myPix.length > 0)
                    ">{{ $t('添加帳户') }}</el-button>
                </div>
                <el-tabs v-model="activeTab">

                    <template v-for="paytypeItem in paytype">
                        <el-tab-pane v-if="paytypeItem == $t('银行卡')" :label="$t('银行卡')" :name="$t('银行卡')">
                            <swiper v-if="myBank.length > 0" class="swiper swiper-card" :options="swiperCard"
                                ref="swiperCard">
                                <swiper-slide v-for="(item, index) in myBank" :key="`myBank${index}`">
                                    <div class="admin-card bank-card">
                                        <div class="bank-title">
                                            <span class="iconfont icon-bankcard"></span>
                                            <span>{{ item.bank_hm }} <i style="font-size:12px;">({{ item.currency_code
                                                    }})</i> </span>
                                        </div>
                                        <div class="bank-val">**** **** **** **** {{ item.bankcrad }}</div>
                                        <div class="bank-name">{{ item.bank_name }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <el-button class="button_jade content-add-button" v-else size="large"
                                @click="showAddAccount">{{ $t("新增賬戶")
                                }}</el-button>
                        </el-tab-pane>
                        <el-tab-pane v-if="paytypeItem == $t('USDT')" :label="$t('USDT')" :name="$t('USDT')">
                            <swiper v-if="myUsdt.length > 0" class="swiper swiper-card" :options="swiperCard"
                                ref="swiperCard">
                                <swiper-slide v-for="(item, index) in myUsdt" :key="`myUsdt${index}`">
                                    <div class="admin-card usdt-card">
                                        <div class="bank-title">
                                            <span class="iconfont icon-USDT"></span>
                                            <span>{{ item.bank_name }}</span>
                                        </div>
                                        <div class="bank-val">**** **** **** **** {{ item.bankcrad }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <el-button class="button_jade content-add-button" v-else size="large"
                                @click="showAddAccount">{{ $t("新增賬戶")
                                }}</el-button>
                        </el-tab-pane>

                        <el-tab-pane v-if="paytypeItem == $t('电子钱包')" :label="$t('电子钱包')" :name="$t('电子钱包')">
                            <swiper v-if="myWallet.length > 0" class="swiper swiper-card" :options="swiperCard"
                                ref="swiperCard">
                                <swiper-slide v-for="(item, index) in myWallet" :key="`myWallet${index}`">
                                    <div class="admin-card elec-card">
                                        <div class="bank-title">
                                            <span class="iconfont icon-qianbao"></span>
                                            <span>{{ $t('電子錢包') }}</span>
                                        </div>
                                        <div class="bank-val">**** **** **** **** {{ item.bankcrad }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <el-button class="button_jade content-add-button" v-else size="large"
                                @click="showAddAccount">{{ $t("新增賬戶")
                                }}</el-button>
                        </el-tab-pane>

                        <el-tab-pane v-if="paytypeItem == $t('PIX')" :label="$t('PIX')" :name="$t('PIX')">
                            <swiper v-if="myPix.length > 0" class="swiper swiper-card" :options="swiperCard"
                                ref="swiperCard">
                                <swiper-slide v-for="(item, index) in myPix" :key="`myPix${index}`">
                                    <div class="admin-card pix-card">
                                        <div class="bank-title">
                                            <span class="iconfont icon-jinbi"></span>
                                            <span>{{ $t('PIX') }}</span>
                                        </div>
                                        <div class="bank-val">**** **** **** **** {{ item.bankcrad }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <el-button class="button_jade content-add-button" v-else size="large"
                                @click="showAddAccount">{{ $t("新增賬戶")
                                }}</el-button>
                        </el-tab-pane>
                    </template>

                </el-tabs>
            </div>
        </el-dialog>

        <!-- add card -->
        <el-dialog v-if="$store.state.userInfo" :visible.sync="bankshow" custom-class="custom-dialog "
            :modal-append-to-body="false">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ $t('添加銀行卡') }}</h1>
                </div>
            </div>
            <div class="modal__body">
                <div class="form__body">
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('戶名') }}</span>
                                <el-input clearable v-model="KaihuName" :placeholder="$t('輸入戶名')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15" v-if="bankNameList && bankNameList.length > 0">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('銀行名稱') }}</span>
                                <el-select ref="bankSelect"
                                 @focus="handleFocus" 
                                 v-model="BankName" 
                                 filterable
                                @hook:mounted="handleFocus"
                                @visible-change="handleFocus"
                                @blur.native.capture="onblur"
                                 :placeholder="$t('请选择')">
                                    <el-option v-for="(item, index) in bankNameList" :key="index"
                                        :label="item.bank_name + '---' + item.bank_code" :value="item.bank_name">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15" v-else>
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('銀行名稱') }}</span>
                                <el-input clearable v-model="BankName" :placeholder="$t('輸入銀行名稱')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input input_md">
                                <span class="input__label">{{ $t('銀行帳戶') }}</span>
                                <el-input type="number" clearable v-model="BankNumber"
                                    :placeholder="$t('輸入銀行帳戶')"></el-input>
                            </div>
                        </div>
                    </div>

                    <div class="input-block el-m-b-15" v-if="bankFinancial == 1">
                        <div class="input-block__body">
                            <div class="input input_md">
                                <span class="input__label">{{ $t('金融代碼') }}</span>
                                <el-input clearable v-model="BankFinancialNumber"
                                    :placeholder="$t('輸入金融代碼')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15" v-if="isBankOpen == 1">
                        <div class="input-block__body">
                            <div class="input input_md">
                                <span class="input__label">{{ $t('開戶行') }}</span>
                                <el-input clearable v-model="BankIsBankOpen" :placeholder="$t('輸入開戶行')"></el-input>
                            </div>
                        </div>
                    </div>

                    <div class="btn_wrap">
                        <el-button :loading="$store.state.bottonLoading" class="button_jade button_fullwidth"
                            @click="addBankCar(0)">{{
                                $t('新增') }}</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- add elec -->
        <el-dialog v-if="$store.state.userInfo" :visible.sync="elecshow" custom-class="custom-dialog "
            :modal-append-to-body="false">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ $t('新增電子錢包') }}</h1>
                </div>
            </div>
            <div class="modal__body">
                <div class="form__body">
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('開戶名') }}</span>
                                <el-input clearable v-model="elecName" :placeholder="$t('輸入開戶名')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('錢包號碼') }}</span>
                                <el-input clearable v-model="elecNumber" :placeholder="$t('輸入錢包號碼')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <el-button :loading="$store.state.bottonLoading" class="button_jade button_fullwidth"
                            @click="addBankCar(2)">{{
                                $t('新增') }}</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- add usdt -->
        <el-dialog v-if="$store.state.userInfo" :visible.sync="usdtshow" custom-class="custom-dialog "
            :modal-append-to-body="false">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ $t('新增USDT') }}</h1>
                </div>
            </div>
            <div class="modal__body">
                <div class="form__body">
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('收幣地址') }}</span>
                                <el-input clearable v-model="usdtUrl" :placeholder="$t('輸入收幣地址')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <span class="input__label">{{ $t('鏈名稱') }}</span>
                                <el-select v-model="usdtNameValue" :placeholder="$t('選擇鏈名稱')" style="width: 100%;"
                                    popper-class="dw-popper-class" :disabled="caterange.length < 2">
                                    <template #prefix>
                                        <div class="select-pre"
                                            v-if="caterange.find(item => item.name == usdtNameValue)">
                                            <img width="20" height="20"
                                                :src="caterange.find(item => item.name == usdtNameValue).img"
                                                class="currency__icon" />
                                        </div>
                                    </template>
                                    <el-option v-for="(item, index) in caterange" :key="index" :label="item.name"
                                        :value="item.name">
                                        <button class="dropdown-menu__link">
                                            <span class="currency is-wallet">
                                                <img width="20" height="20" :src="item.img" alt="ETH"
                                                    class="currency__icon" />
                                                <span class="currency__label">{{ item.name }}</span>
                                            </span>
                                        </button>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <el-button :loading="$store.state.bottonLoading" class="button_jade button_fullwidth"
                            @click="addBankCar(1)">{{
                                $t('新增') }}</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- add pix -->
        <el-dialog v-if="$store.state.userInfo" :visible.sync="pixshow" custom-class="custom-dialog "
            :modal-append-to-body="false">
            <div class="custom-dialog-head" slot="title">
                <div class="header__title">
                    <h1>{{ $t('新增PIX') }}</h1>
                </div>
            </div>
            <div class="modal__body">
                <div class="form__body">
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input address_input input_md">
                                <div class="input__label">{{ $t('開戶名') }}</div>
                                <el-input clearable v-model="pixName" :placeholder="$t('輸入開戶名')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15">
                        <div class="input__label">{{ $t('金鑰類型') }}</div>
                        <!-- <el-select size="small" v-model="pixTypeValue" :placeholder="$t('请选择')" class="qhClass">
                            <el-option v-for="item in pixType" :key="`pixType${item.value}`" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>   -->
                        <div class="card-wrap">
                            <div class="card-items" :class="pixTypeValue === item.name ? 'active' : ''"
                                v-for="item in pixType" :key="`pixType${item.value}`" @click="pixTypeValue = item.name">
                                {{ $t(`${item.name}`) }}
                            </div>
                        </div>
                    </div>
                    <div class="input-block el-m-b-15">
                        <div class="input-block__body">
                            <div class="input input_md">
                                <div class="input__label">{{ $t('鑰匙' + pixTypeValue) }}</div>
                                <el-input clearable v-model="pixNumber" :placeholder="$t('輸入鑰匙' + pixTypeValue)"
                                    :class="pixTypeValue == 'Phone' ? 'input-key' : ''">
                                    <span slot="prefix" class="phone-label" v-if="pixTypeValue == 'Phone'">+55</span>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <el-button :loading="$store.state.bottonLoading" class="button_jade button_fullwidth"
                            @click="addBankCar(3)">{{
                                $t('添加') }}</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <verify ref="verify" @enter="verifyEnter"></verify>
    </div>
</template>

<script>
import verify from "@/components/verify.vue";
import {
    bank_add_api,
    bank_lists_api,
    bank_my_api
} from "@/api/user";
export default {
    components: {
        verify
    },
    data() {
        return {
            dialogVisibleCard: false,
            activeTab: "",
            myBank: [],
            myUsdt: [],
            myWallet: [],
            myPix: [],
            paytype: [],
            swiperCard: {
                slidesPerView: "auto",
                slidesPerGroup: 3,
                spaceBetween: 16
            },
            bankshow: false,
            KaihuName: "",
            bankNameList: [],
            BankName: "",
            BankNumber: "",
            bankFinancial: 0,
            BankFinancialNumber: "",
            isBankOpen: 1,
            BankIsBankOpen: "",
            elecshow: false,
            elecName: "",
            elecNumber: "",
            usdtshow: false,
            usdtUrl: "",
            usdtNameValue: "",
            caterange: [],
            pixshow: false,
            pixName: "",
            pixTypeValue: "",
            pixType: [],
            pixNumber: "",
            verify_code: "",
        }
    },
    mounted() {
    },
    methods: {
        handleFocus() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const { bankSelect } = this.$refs
                    const input = bankSelect.$el.querySelector('.el-input__inner')
                    input.removeAttribute('readonly')
                }, 200)
            })
        },
        onblur(){
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判斷iPhone|iPad|iPod|iOS
						this.$refs.bankSelect.blur();
					}
        },
        verifyEnter(code, type) {
            if (type === "bankcard_check") {
                this.verify_code = code;
                this.verify_code && this.addBankCar(this.addBankCarI);
            } else if (type === "withdrawal_check") {
                this.verify_code = code;
                // this.verify_code && this.addBankCar(this.withdrawPwdQ);
            }
        },
        verifyShow(type, code) {
            this.verify_code = "";
            this.$refs.verify.show(type, code);
        },
        verifyClose() {
            this.verify_code = "";
            this.$refs.verify.close();
        },
        cardClick() {
            this.getBankMyList();
            this.getBankLists();
            this.dialogVisibleCard = true;
            this.dialogVisible = false;
            this.userShow = false;
        },

        showAddAccount() {
            this.bankshow = this.activeTab === this.$t('银行卡');
            this.elecshow = this.activeTab === this.$t('电子钱包');
            this.usdtshow = this.activeTab === this.$t('USDT');
            this.pixshow = this.activeTab === this.$t('PIX');
        },
        async addBankCar(i) {
            this.addBankCarI = i;
            let parm = {};
            if (i == 0) {
                if (this.KaihuName == "") {
                    this.errorTips("輸入戶名");
                    return;
                }
                if (this.BankName == "") {
                    this.errorTips("輸入銀行名稱");
                    return;
                }
                if (this.BankNumber == "") {
                    this.errorTips("輸入銀行帳戶");
                    return;
                }
                parm = {
                    bank_type: 0,
                    currency_id: this.bankCurrencyId,
                    bank_name: this.BankName,
                    bank_number: this.BankNumber,
                    bank_open: this.BankIsBankOpen,
                    bank_hm: this.KaihuName,
                    financial: this.BankFinancialNumber,
                    withdrawal_psd: "",
                    verify_code: this.verify_code
                };
            } else if (i == 1) {
                if (this.usdtNameValue == "") {
                    this.errorTips("選擇鏈名稱");
                    return;
                }
                if (this.usdtUrl == "") {
                    this.errorTips("輸入收幣地址");
                    return;
                }
                parm = {
                    bank_type: 1,
                    bank_name: this.usdtNameValue,
                    bank_number: this.usdtUrl,
                    bank_open: "",
                    bank_hm: this.usdtNameValue,
                    withdrawal_psd: "",
                    verify_code: this.verify_code
                };
            } else if (i == 2) {
                if (this.elecName == "") {
                    this.errorTips("輸入開戶名");
                    return;
                }
                if (this.elecNumber == "") {
                    this.errorTips("輸入錢包號碼");
                    return;
                }
                parm = {
                    bank_type: 2,
                    bank_name: this.elecName,
                    bank_hm: this.elecName,
                    bank_number: this.elecNumber,
                    bank_open: "",
                    withdrawal_psd: "",
                    verify_code: this.verify_code
                };
            } else if (i == 3) {
                if (this.pixName == "") {
                    this.errorTips("輸入開戶名");
                    return;
                }
                if (this.pixTypeValue == "") {
                    this.errorTips("選擇金鑰型別");
                    return;
                }
                if (this.pixNumber == "") {
                    this.errorTips("輸入鑰匙");
                    return;
                }
                parm = {
                    bank_type: 3,
                    bank_name: this.pixTypeValue,
                    bank_hm: this.pixName,
                    bank_number: this.pixNumber,
                    financial: "",
                    withdrawal_psd: "",
                    verify_code: this.verify_code
                };
            }
            if (this.verify_code) {
                parm.check_type = this.$store.state.userInfo.mail ? 2 : 1
            } else {
                parm.check_type = 0
            }
            this.$store.commit("$vuexSetBottonLoadingState", true);
            await bank_add_api(parm).then(response => {
                let res = response.data;
                if (res.code == 1) {
                    this.successTips(res.msg);
                    this.getBankMyList();
                    this.bankshow = false;
                    this.usdtshow = false;
                    this.elecshow = false;
                    this.pixshow = false;
                    this.verifyClose();
                } else if (res.code == 110 || res.code == 120) {
                    this.verify_code && this.errorTips(res.msg);
                    this.verifyShow("bankcard_check", res.code);
                } else {
                    this.verifyClose();
                    this.errorTips(res.msg);
                }
                this.$store.commit("$vuexSetBottonLoadingState", false);
            });
        },
        async getBankMyList() {
            this.myBank = [];
            this.myWallet = [];
            this.myUsdt = [];
            this.myPix = [];
            await bank_my_api({ bank_type: "all" }).then(res => {
                if (res && res.data.code == 1) {
                    this.myBankList = res.data.data;
                    let arr = res.data.data;
                    for (let i in arr) {
                        if (arr[i].bank_type == 0) {
                            this.myBank.push(arr[i]);
                        } else if (arr[i].bank_type == 1) {
                            this.myUsdt.push(arr[i]);
                        } else if (arr[i].bank_type == 2) {
                            this.myWallet.push(arr[i]);
                        } else {
                            this.myPix.push(arr[i]);
                        }
                    }
                }
            });
        },

        async getBankLists() {
            await bank_lists_api().then(response => {
                let res = response.data;
                if (res && res.code == 1) {
                    this.bankNameList = res.data.bank_list[0];
                    this.allCurrency = res.data.all_currency;
                    for (const key in this.allCurrency) {
                        if (Object.hasOwnProperty.call(this.allCurrency, key)) {
                            const element = this.allCurrency[key];
                            if (element['country_code'] == this.$helper.getLang()) {
                                this.bankCurrencyId = element['id'];
                            }
                        }
                    }
                    let paytype_obj = res.data.paytype;
                    let paytype_arr1 = [];
                    for (var a in paytype_obj) {
                        paytype_arr1.push(paytype_obj[a]);
                    }
                    this.paytype = paytype_arr1;
                    this.activeTab = this.$t(this.paytype[0])
                    let usdtType = res.data.usdt_type;
                    this.caterange = []
                    for (let i in usdtType) {
                        this.caterange.push({
                            value: i,
                            name: usdtType[i]
                        });
                        this.caterange.forEach(item => {
                            if (item.name.includes("TRC")) {
                                item.img = require('@/assets/images/TRC20.png')
                            }
                            if (item.name.includes("ERC")) {
                                item.img = require('@/assets/images/ERC20.png')
                            }
                        })
                    }
                    if (this.caterange.length == 1) {
                        this.usdtNameValue = this.caterange[0].value
                    }
                    let arr = res.data.pix_type;
                    this.pixType = []
                    for (let i in arr) {
                        this.pixType.push({
                            value: i,
                            name: arr[i]
                        });
                    }
                    this.pixTypeValue = this.pixType[0].name
                }
            });
        },
    },

}
</script>

<style lang="less" scoped></style>