<template>
    <div class="main-container">
        <div class="mb_header">
            <div class="bg_header">
                <button @click="goBack" type="button" class="btn_back"></button>
                <span>{{ $t("我") }}</span>
                <router-link to="/xwNotice" class="btn_bell on" ></router-link>
            </div>
        </div>

        <div class="container_main memberBox">
            <div class="member">
                <div class="memberFace">
                    <div class="portrait">
                        {{ $t('銅') }}
                    </div>
                    <span>{{ $store.state.userInfo.level_name }}</span>
                </div>
                <div class="memberID" v-if="$store.state.showUserId == 1">
                    <span>{{ $t('ID') }}:
                        {{ $store.state.userInfo ? $store.state.userInfo.username || $store.state.userInfo.phone : '' }}
                    </span>
                </div>
            </div>

            <div class="memberNum">
                <div @click="amountShow = !amountShow" class="btn_mAmount " :class="{ amountEye: !amountShow }"
                    v-loading="false" element-loading-background="#fff">
                    <span v-if="amountShow">
                        {{ this.$helper.get("rule").currency.code }}
                        {{ numFormat($store.state.userInfo.balance) }}
                    </span>
                </div>
                <div>{{ $t('總額度') }}</div>
            </div>

            <div class="memberOne">
                <router-link to="/memberCenter/TXRECORDS" class="memberOne_item">
                    <div class="icon_mRec">
                        <img src="@/assets/images/xw/icon_mRec.svg" alt="">
                    </div>
                    <span>{{ $t('交易記錄') }}</span>
                </router-link>
                <button @click="setting" type="button" class="memberOne_item">
                    <div class="icon_mRec">
                        <img src="@/assets/images/xw/icon_mCard.svg" alt="">
                    </div>
                    <span>{{ $t('銀行管理') }}</span>
                </button>
                <router-link to="/memberCenter/USERINFO" class="memberOne_item">
                    <div class="icon_mRec">
                        <img src="@/assets/images/xw/icon_member.svg" alt="">
                    </div>
                    <span>{{ $t('變更資料') }}</span>
                </router-link>
                <!-- <router-link to="/" class="memberOne_item">
                    <div class="icon_mRec">
                        <img src="@/assets/images/xw/icon_mPSW.svg" alt="">
                    </div>
                    <span>{{ $t('修改密碼') }}</span>
                </router-link> -->
            </div>

            <div class="memberTwo">
                <router-link to="/memberCenter/TRANSFER?tab=2" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mTransRec.svg" alt="">
                    </div>
                    <span>{{ $t('賬款記錄') }}</span>
                </router-link>

                <router-link to="/memberCenter/FLOWRECORDS" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mComplian.svg" alt="">
                    </div>
                    <span>{{ $t('流水明細') }}</span>
                </router-link>

                <button type="button" @click="sharePop = true" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mShare.svg" alt="">
                    </div>
                    <span>{{ $t('邀请码') }}</span>
                </button>

                <button type="button" @click="toLiveSuppert" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mShare.svg" alt="">
                    </div>
                    <span>{{ $t('下載APP') }}</span>
                </button>

                <!-- <router-link to="/" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mComplian.svg" alt="">
                    </div>
                    <span>{{ $t('投訴箱') }}</span>
                </router-link> -->

                <button type="button" @click="pageJump('/aboutUs')" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mAbout.svg" alt="">
                    </div>
                    <span>{{ $t('關於我們') }}</span>
                </button>

                <button type="button" @click="pageJump('/problem')" class="memberTwo_item">
                    <div class="icon_mTransRec">
                        <img src="@/assets/images/xw/icon_mHelp.svg" alt="">
                    </div>
                    <span>{{ $t('常見問題') }}</span>
                </button>
            </div>

            <el-button type="button" class="btn_logout" :loading="loginOutLoading" @click="loginOut">
                {{ $t('登出') }}</el-button>
        </div>

        <xwTabbar />
        <xwBankList ref="xwBankList" />

        <div v-if="sharePop" class="mod_overlay">
            <div class="mod_container" @click.stop>
                <div class="mod_header">
                    <span class="mod_title">{{ $t('邀请码') }}</span>
                    <button class="mod_close" @click="closeSharePop"></button>
                </div>
                <div class="mod_body">
                    <div class="shareApp">
                        <el-image :src="logo" class="app_logo"></el-image>
                        <div class="shareQR unselectable">
                            <div class="code-cell">
                                <vue-qr :logo-src="logo" v-if="$store.state.userInfo" :size="176" :margin="0"
                                :auto-color="true" :dot-scale="1" :text="$store.state.userInfo?.invite_url" />
                            </div>
                        </div>
                        <div class="share_info_wrap">
                            <div class="share_item">
                                <span class="share_title">{{ $t('code') }}：</span>
                                <span>{{ $store.state.userInfo?.rec_code || '' }}</span>
                                <button class="share_copy_btn" type="button" v-clipboard:copy="$store.state.userInfo?.rec_code" v-clipboard:success="getRecUrl">
                                    <img src="@/assets/images/xw/icon_share_link.svg" alt="">
                                </button>
                                
                            </div>
                            <div class="share_item">
                                <span class="share_title">{{ $t('推广链接') }}：</span>
                                <span class="share_url">{{ $store.state.userInfo?.invite_url || '' }}</span>
                                <button class="share_copy_btn" type="button" v-clipboard:copy="$store.state.userInfo?.invite_url" v-clipboard:success="getRecUrl">
                                    <img src="@/assets/images/xw/icon_share_link.svg" alt="">
                                </button>
                            </div>
                            
                            <router-link to="/team-center">
                                {{ $t('推廣中心') }}
                            </router-link>
                        </div>

                        <!-- <div class="btnBox">
                            <button type="button" v-clipboard:copy="downloadUrl" v-clipboard:success="getRecUrl" class="btn_share"> {{ $t('複製') }}</button>
                            <button type="button" @click="toLiveSuppert" class="btn_share btn_share_down" > {{ $t('儲存') }}</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import xwTabbar from "@/components/xwTabbar.vue";
import xwBankList from "@/components/xwBankList.vue";
import {
    my_info_api,
    my_logout_api
} from "@/api/index";
import xwModel from "@/components/xwModel.vue";
import VueQr from 'vue-qr'
export default {
    components: {
        xwTabbar,
        xwBankList,
        xwModel,
        VueQr
    },
    data() {
        return {
            logo: process.env.VUE_APP_SITE_LOGO,
            amountShow: false,
            loginOutLoading: false,
            sharePop:false,
            downloadUrl:'',
        }
    },
    mounted() {
        if (this.$store.state.windowWidth > 1000) {
            this.$router.push({ path: '/' })
        }
        this.updateUserInfo();
        this.initDownUrl()
    },
    methods: {
        pageJump(path) {
            if (this.$store.state.windowWidth > 1000) {
                this.openNewWindowAutoHeightName(`${window.location.origin}${this.$router.mode === 'hash' ? '/#' : ''}${path}`, 'ExampleWindow', 1002, 674)
            }else{
                this.$router.push({path})
            }
        },
        toLiveSuppert() {
			const ua = navigator.userAgent
			if (/android/i.test(ua) || /iphone|ipad|ipod/i.test(ua)) {
				if (/android/i.test(ua)) {
					window.open(this.downloadUrl, '_blank');
				} else {
					window.location.href = this.downloadUrl;
				}
			} else {
				window.open(this.downloadUrl, '_blank');
			}
		},
        getRecUrl() {
			this.successTips('複製成功');
		},
        initDownUrl(){
            let rule = this.$helper.get("rule");
            const ua = navigator.userAgent
			if ( /iphone|ipad|ipod/i.test(ua)) {
				this.downloadUrl = rule.ios_download_url
			} else {
                this.downloadUrl = rule.android_download_url
			}
        },
        closeSharePop(){
            this.sharePop = false
        },
        goBack() {
            this.$router.go(-1);
        },
        setting() {
            this.$refs.xwBankList.cardClick()
        },
        async loginOut() {
            this.loginOutLoading = true;
            this.$helper.rm("userInfo");
            await my_logout_api().then(res => {
                if (res && res.data.code == 1) {
                    this.userShow = false;
                    this.$helper.rm("tokenInfo");
                    this.$helper.rm("userInfo");
                    this.$store.commit("$vuexSetLoginState", false);
                    this.$store.commit("$vuexUserInfo", null);
                    this.$message({
                        customClass: "successMessage",
                        showClose: false,
                        message: this.$t("已成功退出當前賬號"),
                        center: true,
                        type: "success",
                        duration: "1000",
                        onClose: () => {
                            this.goUrl('/');
                        }
                    });
                } else {
                    this.errorTips("失敗");
                }
                this.loginOutLoading = false;
                this.$helper.rm("tipStorage");
            });
        },
    }
}
</script>

<style lang="less" scoped>
.main-container {
    background-color: #fff;
    min-height: var(--vh);
}

.mb_header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 12;
}

.bg_header {
    color: #fff;
    text-align: center;
    height: 40px;
    font-weight: bold;
    position: relative;
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.btn_bell {
    background: none;
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    top: 0;
    background: url('../../assets/images/xw/btn_bell.svg') no-repeat center;
    background-size: 20px;
}

.btn_bell.on {
    &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #f00;
        border-radius: 50%;
        right: 1.2vw;
        top: 0;
        bottom: 35%;
        margin: auto;
    }
}

.container_main {
    padding: 40px 0 43px;
    margin: 0 auto;
    height: var(--vh);
    width: 100%;
    display: table;
    background-color: #f0eff5;
}

.member {
    position: relative;
    width: 100%;
    padding: 5px 0;
    list-style: none;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 180px;
        background: #9ca2ac url('../../assets/images/xw/bg_member.png') no-repeat center;
        background-size: cover;
        top: -40px;
        z-index: -1;
    }
}

.memberFace {
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
        margin-top: -5px;
        background-color: #fff;
        border-radius: 100px;
        display: inline-block;
        padding: 3px 10px 2px;
        font-size: 16px;
        min-width: 75px;
        box-sizing: border-box;
        text-align: center;
        line-height: 1em;
        color: #000;
    }
}

.portrait {
    width: 50px;
    height: 50px;
    background-color: #836634;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.memberID {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1em;
    color: #fff;
    margin: 12px auto;
}

.memberNum {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    line-height: 1em;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    width: 94.2%;
    box-sizing: border-box;
    color: #000;
    z-index: 2;
}

.btn_mAmount {
    font-size: 22px;
    width: 100%;
    height: 1em;
    text-align: center;
    padding: 20px 0 8px;
    box-sizing: content-box;
    color: #ff7800;
}

.amountEye {
    position: relative;

    &::after {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
        margin: auto;
        background-image: url(../../assets/images/xw/icon_pwOFF.svg);
        background-size: auto 25px;
        background-position-y: 2px;
    }
}

.memberOne {
    display: flex;
    justify-content: space-between;
    padding: 15px 3%;
    margin: 10px auto 0;
    background-color: #fff;
    border-radius: 5px;
    width: 94.2%;
    box-sizing: border-box;
}

.memberOne_item {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #000;
    text-decoration: none !important;
    padding: 0 !important;
}

.icon_mRec {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    margin-bottom: 8px;
    background-color: #dbe2e9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 28px;
        height: 28px;
    }
}

.memberTwo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 5%;
    margin: 10px auto 0;
    background-color: #fff;
    border-radius: 5px;
    width: 94.2%;
    box-sizing: border-box;

    .memberTwo_item {
        padding: 0 !important;
        &:nth-child(2n+1) {
            &::after {
                content: '';
                position: absolute;
                width: calc(200% + 10px);
                height: 1px;
                background-color: #eee;
                left: 0;
                top: -1px;
                padding: 0;
            }
        }
    }

}

.memberTwo_item {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000;
    text-decoration: none !important;
    gap: 12px;
    position: relative;


}

.btn_logout {
    margin: 10px auto 15px;
    width: 94.2%;
    height: 48px !important;
    background-color: #fff !important;
    color: #f00 !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    display: block;
    font-weight: 500 !important;
}

.icon_mTransRec {
    img {
        width: 28px;
        height: 28px;
    }
}









::v-deep .el-loading-spinner {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    position: absolute;
}


.mod_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.mod_container {
    background: transparent;
    border-radius: 6px;
    overflow: hidden;
    width: 500px;

    @media (max-width:1000px) {
        width: 88%;
        max-width: 500px;
    }
}

.mod_header {
    color: #fff;
    height: 70px;
    background-color: #3f9eff;
    text-align: center;
    font-size: 22px;
    line-height: 70px;
    position: relative;

    @media (max-width:1000px) {
        height: 45px;
        font-weight: bold;
        position: relative;
        line-height: 45px;
        font-size: 16px;
    }
}

.mod_title {
    margin: 0;
}

.mod_close {
    width: 50px;
    height: 50px;
    background: url('../../assets/images/xw/btn_close.svg') no-repeat center;
    background-size: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: .5;

    &:hover {
        opacity: 1;
    }

    @media (max-width:1000px) {
        width: 40px;
        height: 40px;
        opacity: 1;
    }
}

.mod_body {
    padding: 0;
    text-align: center;
    color: #333;
    position: relative;
    overflow: hidden;
    padding: 0 !important;
    @media (max-width:1000px) {
        max-height: calc(var(--vh) - 100px);
        overflow-y: auto;
    }
}

.shareApp{
    background: url('../../assets/images/xw/bg_shareAPP.png') #fff center no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 407px;
    position: relative;
    background-size: 120% 120% !important;
    border-radius: 0 0 13px 13px !important;
    font-size: 15px;
    line-height: 1.3;
}

.app_logo{  
    display: table; 
    width: 33%;
    max-width: 130px;
    height: auto;
    margin: 2.6% auto;
}

.code-cell{
    margin: 0 auto 4%;
    width: fit-content;
    min-width: 186px;
    min-height: 186px;
    padding: 10px;
    border: 1px solid #c2c5c7;
    background-color: #fff;
    border-radius: 10px;
}
.btn_share{
    width: 33.333%;
    max-width: 120px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    padding: 27px 0 0 !important;
    background: url('../../assets/images/xw/icon_share_link.svg') top center no-repeat;
    background-size: 23%;
    border-right: 1px solid #e6e6e6;
}

.btn_share_down{
    background: url('../../assets/images/xw/icon_share_download.svg') top center no-repeat;
    background-size: 23%;
    border: none;
}

.share_info_wrap{
    padding: 0 30px;
}
.share_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .share_title{
        text-align: left;
        min-width: 80px;
    }
    .share_url{
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.share_copy_btn{
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-left: 10px;
}
</style>